import IconLocation from "../../../../assets/icons/icon-location.svg";
import IconClockWaiting from "../../../../assets/icons/icon-clock-waiting.svg";
import IconShippingTime from "../../../../assets/icons/icon-shipping-time.svg";
import IconCalendar from "../../../../assets/icons/icon-calendar.svg";

const getProperties = merchantDetail => [
  {
    key: "Ticaret Ünvanı",
    val:
      merchantDetail?.merchantOptions &&
      merchantDetail?.merchantOptions?.brandName !== "" &&
      merchantDetail?.merchantOptions?.brandName !== null
        ? merchantDetail?.merchantOptions?.brandName
        : merchantDetail?.legalName || "-"
  },
  { key: "Kayıtlı E-Posta Adresi", val: merchantDetail?.kep || "-" },
  {
    key: "VKN",
    val: merchantDetail?.financialInformation?.financialIdentityNumber || "-"
  },
  { key: "Mersis No", val: merchantDetail?.mersisNumber || "-" },
  {
    key: "Merkez Adresi",
    val:
      merchantDetail?.billingAddress?.cityName?.charAt(0)?.toUpperCase() +
        merchantDetail?.billingAddress?.cityName?.slice(1)?.toLowerCase() || "-"
  },
  {
    key: "İletişim",
    val:
      "Satıcı firmanın onaylanmış elektronik iletişim adresi ve doğrulanan merkez adresi Hepsiburada.com kayıtlarında yer almaktadır."
  }
];

const MORIA_OPTIONS = {
  id: 99,
  componentId: 99,
  isFullWidth: true,
  componentTypeName: "moriaListing",
  margin: 0,
  cacheStatus: true,
  cacheKey: "moriaListing",
  paramater: {},
  isMandatory: false,
  imagesLoadEagerly: false,
  scriptsLoadEagerly: true,
  onlyClientSide: false,
  passCookies: false
};
const ADDITIONAL_INFO_KEYS = {
  primaryShippingCity: "primaryShippingCity",
  avgDispatchTime: "avgDispatchTime",
  answerDuration: "answerDuration",
  timeOnHepsiburada: "timeOnHepsiburada"
};

const ADDITIONAL_INFO_HEADERS = {
  [ADDITIONAL_INFO_KEYS.answerDuration]: "Soru cevaplama süresi",
  [ADDITIONAL_INFO_KEYS.primaryShippingCity]: "Gönderim adresi",
  [ADDITIONAL_INFO_KEYS.avgDispatchTime]: "Ortalama kargolama süresi",
  [ADDITIONAL_INFO_KEYS.timeOnHepsiburada]: "Hepsiburada'daki süresi"
};
const ADDITIONAL_INFO_ICONS = {
  [ADDITIONAL_INFO_KEYS.answerDuration]: IconClockWaiting,
  [ADDITIONAL_INFO_KEYS.primaryShippingCity]: IconLocation,
  [ADDITIONAL_INFO_KEYS.avgDispatchTime]: IconShippingTime,
  [ADDITIONAL_INFO_KEYS.timeOnHepsiburada]: IconCalendar
};

export { getProperties, MORIA_OPTIONS, ADDITIONAL_INFO_KEYS, ADDITIONAL_INFO_HEADERS, ADDITIONAL_INFO_ICONS };
