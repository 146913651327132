import React, { useEffect } from "react";
import styles from "./ProfileInfoCard.scss";
import DaVinciService from "../../../../services/davinciService";
import { DAVINCI_PAGE_MERCHANT } from "Utils/constants";

const ProfileInfoCard = ({ header, value, Icon, merchantDetail }) => {
  const davinci = DaVinciService(merchantDetail, DAVINCI_PAGE_MERCHANT);
  useEffect(() => {
    davinci.additionalInfoEvent(header);
  }, []);

  return (
    <div className={styles.infoCard}>
      <div className={`${styles.icon}`}>
        <img src={Icon} alt='' />
      </div>
      <div className={styles.info}>
        <div className={styles.header}>{header}</div>
        <div className={styles.text}>{value || "-"}</div>
      </div>
    </div>
  );
};

export default ProfileInfoCard;
