import React from "react";
import Collapse from "@cosmos/collapse";
import PropTypes from "prop-types";
import styles from "./AboutSeller.scss";
import { ADDITIONAL_INFO_HEADERS, ADDITIONAL_INFO_ICONS, ADDITIONAL_INFO_KEYS, getProperties } from "./constants";
import SectionCard from "../../../components/SectionCard/SectionCard";
import ProfileInfoCard from "./ProfileInfoCard";
import { hermesVoltranExternalUrl } from "../../../../services/urls";
import HModule from "@cosmos/h-module";

const AboutSeller = ({ merchantDetail }) => {
  const isWomenEnt = merchantDetail?.tagList?.some(i =>
    ["woman-entrepreneur", "woman-entrepreneur-logo-biography"].includes(i)
  );

  const isMerchant = getProperties(merchantDetail);
  return (
    <div className={styles.Wrapper}>
      <SectionCard>
        <div className={styles.infoCardWrapper}>
          {Object.keys(ADDITIONAL_INFO_KEYS).map(key => (
            <ProfileInfoCard
              key={key}
              header={ADDITIONAL_INFO_HEADERS[key]}
              value={merchantDetail?.additionalInfo?.[key]}
              Icon={ADDITIONAL_INFO_ICONS[key]}
              merchantDetail={merchantDetail}
            />
          ))}
        </div>
      </SectionCard>
      <div className={styles.collapseWrapper}>
        <Collapse defaultSelectItem={null} containerStyled title={["Satıcı Bilgileri"]}>
          <div className={styles.Root}>
            {isWomenEnt && (merchantDetail?.profileImage || merchantDetail?.description) && (
              <div className={styles.WomenEnt}>
                <div className={styles.WomenEntContent}>
                  <img className={styles.ProfilePicture} src={merchantDetail.profilePicture} alt='Kadın Girişmci' />
                  <div className={styles.Context}>
                    {merchantDetail?.nameAndSurname && (
                      <span className={styles.Name}>{merchantDetail?.nameAndSurname}</span>
                    )}
                    <span className={styles.Title}>
                      {merchantDetail?.profession && " / " + merchantDetail.profession}
                    </span>
                  </div>
                </div>
                {merchantDetail?.description && (
                  <span className={styles.Description}>{merchantDetail.description}</span>
                )}
              </div>
            )}
            <div className={styles.AboutSeller}>
              {isMerchant.map(merchant => (
                <div key={merchant.key} className={styles.Row}>
                  <span className={styles.Label}>{merchant.key}</span>
                  <span className={styles.Value}>{merchant.val}</span>
                </div>
              ))}
            </div>
          </div>
        </Collapse>
      </div>
      <div id='productReviews' className={styles.productReviews}>
        <HModule
          url={`${hermesVoltranExternalUrl}/productReviews?merchantId=${merchantDetail?.id}`}
          name='productReviews'
        />
      </div>
    </div>
  );
};
AboutSeller.propTypes = {
  merchantDetail: PropTypes.object.isRequired
};

export default AboutSeller;
